<div class="container">
  <div class="row mt-2">
    <div class="col-sm">
      <input class="form-control"
        type="text"
        placeholder="Suche nach Title, Artist oder Album ..."
        (keyup)="instantSearch($event)">
    </div>
  </div>
  <div class="row">
    <div class="col-sm">
      <div *ngIf="showHint" class="alert alert-secondary mt-2">
        ⬆️ Starte eine Suche und wünsch Dir Deinen Track!
      </div>
      <ul class="list-group">
        <li class="list-group-item pl-2 border-left border-right border-buttom jukebike-search-item"
          *ngFor="let result of resultList; index as idxResult"
          (click)="wishSong(idxResult)">
          {{ result.artist }}
          -
          {{ result.name }}
        </li>
      </ul>
    </div>
  </div>
</div>
