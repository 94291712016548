<div class="container">
  <ng-container *ngIf="currentWish">
    <div class="row mt-2">
      <div class="col-sm">
        <div class="alert alert-secondary" role="alert">
          {{ currentWish.artist }}
          -
          {{ currentWish.name }}
        </div>
      </div>
      <div class="col-sm form-group">
        <label for="myNameInput">Wünsche ich mir:</label>
        <input id="myNameInput"
          class="form-control"
          type="text"
          placeholder="Mein Name ..."
          [(ngModel)]="myName">
        <button type="button"
          class="btn btn-primary btn-lg btn-block mt-2"
          (click)="submitTrackWish($event)">
          Track wünschen
        </button>
      </div>
    </div>
    <hr class="my-4">
    <div class="row">
      <div class="col-sm">
        Oder statt dessen:
      </div>
    </div>
  </ng-container>
  <div class="row">
    <div class="col-sm">
      <button type="button" class="btn btn-link" routerLink="/search" routerLinkActive="active">Neue Suche starten</button>
    </div>
  <div>
</div>
